import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/D8yL37Hhb7I">
    <SEO title="Why We Should Attend and Support Church - Why Church?" />
  </Layout>
)

export default SermonPost
